<template>
  <div class="wrraper work_times">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" type="button" icon="fa-minus" />
    <!--   search_route="/work-time/add"
    @listToClick="deleteItem"
      search_title="حذف الكل" -->
    <!-- End Breadcrumb -->

    <!-- Start Main Loader -->
    <transition name="fadeInUp" mode="out-in" v-if="loaderPage">
      <MainLoader />
    </transition>
    <!-- End Main Loader -->

    <template v-else>
      <section class="p-3">
        <div class="row justify-content-center">
          <!-- <pre>
          {{ work_days }}
          </pre> -->
          <div
            class="col-sm-6 col-xl-6 col-xxl-6 mb-2"
            v-for="(row, index) in work_days"
            :key="index"
          >
            <v-card class="times-card" elevation="2" shaped>
              <v-card-title class="p-2 justify-content-between">
                {{ row.name }}
                <div>
                  <v-icon class="edit text-white" small @click="editItem(row)">
                    fal fa-edit
                  </v-icon>
                  <v-icon
                    color="white"
                    small
                    class="fw-bold"
                    @click="deleteItem(row)"
                  >
                    fal fa-trash
                  </v-icon>
                </div>
              </v-card-title>
              <v-card-text>
                <div class="row align-items-center" v-if="row.times.length">
                  <!-- <pre>

                    {{ row.times }}
                  </pre> -->
                  <main>
                    <v-data-table
                      class="elevation-1 thumb diff_table"
                      :headers="headers"
                      :items="row.times"
                      :custom-filter="helper_filterSearch"
                      :loading="loading"
                      :loading-text="$t('table.loadingData')"
                      item-key="id"
                      hide-default-footer
                    >
                      <!-- ================== You Can use any slots you want ================== -->
                      <!-- Select no data State -->
                      <template v-slot:no-data>
                        {{ $t("table.noData") }}
                      </template>

                      <template v-slot:[`item.index`]="{ index }">
                        {{ index + 1 }}
                      </template>
                      <template v-slot:[`item.is_active`]="{ item }">
                        <span
                          class="status"
                          :class="item.is_active ? 'paid' : 'unpaid'"
                        >
                          {{ item.is_active ? $t("active") : $t("inactive") }}
                        </span>
                      </template>
                      <template v-slot:[`item.is_free`]="{ item }">
                        <span
                          class="status"
                          :class="item.is_free ? 'paid' : 'unpaid'"
                        >
                          {{
                            item.is_free ? $t("titles.yes") : $t("titles.no")
                          }}
                        </span>
                      </template>

                      <!-- Select actions-->
                      <template v-slot:[`item.actions`]="{ item }">
                        <div class="_actions">
                          <!-- <v-icon class="show" small @click="showItem(item)">
                            fal fa-eye
                          </v-icon> -->
                          <!-- <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon> -->
                          <v-icon
                            class="delete"
                            small
                            @click="deleteItem(item)"
                          >
                            fal fa-trash
                          </v-icon>
                        </div>
                      </template>

                      <!-- ======================== Start Top Section ======================== -->
                      <template v-slot:top>
                        <!-- Delete dialog -->
                        <!-- <v-dialog v-model="dialogDelete" max-width="500px">
                          <v-card>
                            <v-card-title class="text-h5 justify-center">
                              {{ $t("table.deletedialog.areYouSure") }}
                            </v-card-title>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="#1B5E20" @click="deleteItemConfirm">
                                {{ $t("table.deletedialog.ok") }}
                              </v-btn>
                              <v-btn
                                color="#F44336"
                                @click="dialogDelete = false"
                              >
                                {{ $t("table.deletedialog.cancel") }}
                              </v-btn>
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog> -->

                        <!-- Start:: Text Content Modal -->
                        <!-- <TextContentModal
                          @toggleModal="controlReportReasonModalModal"
                          :modalIsActive="reportReasonModalIsOpen"
                          :title="$t('reportReason')"
                          :textContent="reportReasonToShow"
                        /> -->
                        <!-- End:: Text Content Modal -->
                      </template>
                      <!-- ======================== End Top Section ======================== -->
                    </v-data-table>
                  </main>
                  <!-- <v-card class="times-card">
                      <v-card-title class="p-1 justify-content-around fs-6">
                        <span v-if="day.type">
                          {{ day.type.name }}
                        </span>
                      </v-card-title>
                      <v-card-text
                        class="p-2 times d-flex flex-column text-center"
                      >
                        <div class="">
                          <label for="" class="form-label mx-1"> من </label>
                          :
                          <span class="mx-2">{{ day.from }}</span>
                        </div>
                        <div class="">
                          <label for="" class="form-label mx-1"> الي </label>
                          :
                          <span class="mx-2">{{ day.to }}</span>
                        </div>
                      </v-card-text>
                    </v-card> -->
                </div>
                <div v-else class="text-center mt-5">
                  {{ $t("titles.noWorkTimesForThisDay") }}
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </section>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5 justify-center">
            {{ $t("table.deletedialog.areYouSure") }}
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#1B5E20" @click="deleteItemConfirm">
              {{ $t("table.deletedialog.ok") }}
            </v-btn>
            <v-btn color="#F44336" @click="dialogDelete = false">
              {{ $t("table.deletedialog.cancel") }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogEditDay"
        persistent
        :fullscreen="true"
        class="work_times"
        width="100"
      >
        <v-card
          v-if="itemtoEdit"
          class="edit-work-day"
          style="width: 100% !important"
        >
          <v-card-title class="justify-content-center flex-column">
            <span> {{ $t("titles.add/editWorkTimes") }} </span>
            <span> {{ itemtoEdit.name }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <form @submit.prevent="validateForm">
                <div class="row">
                  <section class="p-3">
                    <div class="col-lg-12 py-0">
                      <div class="input_wrapper my-3 top_label">
                        <label for="textarea_1" class="form-label">
                          {{ $t("forms.labels.branches") }}
                        </label>
                        <multiselect
                          v-model="data.branches"
                          :options="branches"
                          label="name"
                          track-by="id"
                          placeholder=" "
                          :searchable="true"
                          :allow-empty="true"
                          :show-labels="false"
                          multiple
                        ></multiselect>
                      </div>
                    </div>
                    <div
                      class="row align-items-center"
                      v-for="(row, index) in itemtoEdit.times"
                      :key="row.uid"
                    >
                      <!-- <pre>{{ row }}</pre> -->
                      <div class="col-11">
                        <div class="row productDetails mb-2">
                          <div class="col-12 mx-auto">
                            <div class="row">
                              <!-- <div class="col-xl-4">
                            <div class="select-wrapper">
                              <div class="input_wrapper my-0 top_label">
                                <label for="" class="form-label">النوع</label>
                                <multiselect
                                  v-model="row.type"
                                  :options="types"
                                  label="name"
                                  track-by="id"
                                  :show-labels="false"
                                  :allow-empty="false"
                                  placeholder=""
                                  :searchable="true"
                                ></multiselect>
                              </div>
                            </div>
                          </div> -->
                              <div class="col-lg-3">
                                <div class="select-wrapper">
                                  <div class="input_wrapper my-0 top_label">
                                    <label for="" class="form-label">
                                      {{ $t("from") }}
                                    </label>

                                    <v-menu
                                      :ref="`time_from_${index}`"
                                      v-model="row.time_from_modal"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      :return-value.sync="row.from"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="290px"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          v-model="row.from"
                                          label=" "
                                          prepend-icon="mdi-clock-time-four-outline"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-time-picker
                                        v-if="row.time_from_modal"
                                        v-model="row.from"
                                        full-width
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          text
                                          color="error"
                                          @click="row.time_from_modal = false"
                                        >
                                          {{ $t("cancel") }}
                                        </v-btn>
                                        <v-btn
                                          text
                                          color="primary"
                                          @click="saveTimeFrom(row.from, index)"
                                        >
                                          {{ $t("save") }}
                                        </v-btn>
                                      </v-time-picker>
                                    </v-menu>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="select-wrapper">
                                  <div class="input_wrapper my-0 top_label">
                                    <label for="" class="form-label">
                                      {{ $t("to") }}
                                    </label>

                                    <v-menu
                                      :ref="`time_to_${index}`"
                                      v-model="row.time_to_modal"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      :return-value.sync="row.to"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="290px"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          v-model="row.to"
                                          label=" "
                                          prepend-icon="mdi-clock-time-four-outline"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-time-picker
                                        v-if="row.time_to_modal"
                                        v-model="row.to"
                                        full-width
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          text
                                          color="error"
                                          @click="row.time_to_modal = false"
                                        >
                                          {{ $t("cancel") }}
                                        </v-btn>
                                        <v-btn
                                          text
                                          color="primary"
                                          @click="saveTimeTo(row.to, index)"
                                        >
                                          {{ $t("save") }}
                                        </v-btn>
                                      </v-time-picker>
                                    </v-menu>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 py-0">
                                <div class="input_wrapper my-3 top_label">
                                  <input
                                    type="number"
                                    class="form-control"
                                    @input="helper_checkIfInputIsEmpty"
                                    v-model="row.num_of_orders"
                                  />
                                  <label for="name_input" class="form-label">
                                    {{ $t("titles.ordersNum") }}
                                  </label>
                                </div>
                              </div>
                              <div class="col-lg-3 py-0">
                                <div class="input_wrapper my-3 top_label">
                                  <input
                                    type="number"
                                    class="form-control"
                                    @input="helper_checkIfInputIsEmpty"
                                    v-model="row.price"
                                  />
                                  <label for="name_input" class="form-label">
                                    {{ $t("titles.price") }}
                                  </label>
                                </div>
                              </div>
                              <!-- Start:: Is Active -->
                              <div class="col-lg-6 py-0">
                                <v-checkbox
                                  :label="$t('forms.labels.is_free')"
                                  v-model="row.is_free"
                                  color="success"
                                ></v-checkbox>
                              </div>
                              <!-- End:: Is Active -->
                              <!-- Start:: Is Active -->
                              <div class="col-lg-6 py-0">
                                <v-checkbox
                                  :label="$t('forms.labels.is_active')"
                                  v-model="row.is_active"
                                  color="success"
                                ></v-checkbox>
                              </div>
                              <!-- End:: Is Active -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-1">
                        <div
                          class="py-0 d-flex justify-center align-center mx-1 mb-4 work_time_append_btn"
                          v-if="index == itemtoEdit.times.length - 1"
                        >
                          <span class="append" @click="appendDaysRow">
                            <i class="fas fa-plus-circle"></i>
                          </span>
                        </div>
                        <div
                          class="py-0 d-flex justify-center align-center mx-1 work_time_append_btn"
                        >
                          <span class="append" @click="deleteDaysRow(row)">
                            <i class="fas fa-minus-circle"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div class="buttons_wrapper d-flex gap-5">
                  <button
                    type="button"
                    class="button_style_1 error ml-3"
                    @click="dialogEditDay = false"
                    :disabled="btnIsLoading"
                  >
                    {{ $t("cancel") }}
                    <!-- <span class="btn_loader" v-if="btnIsLoading"></span> -->
                  </button>

                  <button class="button_style_1 mr-3" :disabled="btnIsLoading">
                    {{ $t("forms.submit") }}
                    <span class="btn_loader" v-if="btnIsLoading"></span>
                  </button>
                </div>
              </form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// START:: Main Loader

// END:: Main Loader
import MainFilter from "@/components/Filters/MainFilter.vue";
import TextContentModal from "@/components/Modals/TextContentModal.vue";

export default {
  components: {
    MainFilter,
    TextContentModal,
  },

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.work-time.title"),
          disabled: false,
          href: "/work-time/show-all",
        },
        {
          text: this.$t("breadcrumb.work-time.all"),
          disabled: true,
          href: "",
        },
      ],
      btnIsLoading: false,
      work_days: [
        {
          name: this.$t("saturday"),
          day: "saturday",
          time_from_modal: false,
          time_to_modal: false,
          times: [],
          exeptions: ["time_from_modal", "time_to_modal"],
          branches: [],
        },

        {
          name: this.$t("sunday"),
          day: "sunday",
          time_from_modal: false,
          time_to_modal: false,
          times: [],
          exeptions: ["time_from_modal", "time_to_modal"],
          branches: [],
        },

        {
          name: this.$t("monday"),
          day: "monday",
          time_from_modal: false,
          time_to_modal: false,
          times: [],
          exeptions: ["time_from_modal", "time_to_modal"],
          branches: [],
        },

        {
          name: this.$t("tuesday"),
          day: "tuesday",
          time_from_modal: false,
          time_to_modal: false,
          times: [],
          exeptions: ["time_from_modal", "time_to_modal"],
          branches: [],
        },
        {
          name: this.$t("wednesday"),
          day: "wednesday",
          time_from_modal: false,
          time_to_modal: false,
          times: [],
          exeptions: ["time_from_modal", "time_to_modal"],
          branches: [],
        },
        {
          name: this.$t("thursday"),
          day: "thursday",
          time_from_modal: false,
          time_to_modal: false,
          times: [],
          exeptions: ["time_from_modal", "time_to_modal"],
          branches: [],
        },
        {
          name: this.$t("friday"),
          day: "friday",
          time_from_modal: false,
          time_to_modal: false,
          times: [],
          exeptions: ["time_from_modal", "time_to_modal"],
          branches: [],
        },
      ],
      work_time: [
        {
          time_from_modal: false,
          time_to_modal: false,
          day: null,
          from: null,
          to: null,
          type: null,
          branches: [],
        },
      ],
      branches: null,
      data: {
        branches: [],
      },
      // ========== dialog Status
      dialogDelete: false,
      itemtoDelete: null,

      dialogEditDay: false,
      itemtoEdit: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Your Data
      rows: [], // injected in created

      // ========== Main Loader
      loaderPage: false,
      // ========== Loding
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.$i18n.locale == "ar") {
        return [
          {
            text: "#",
            align: "center",
            value: "index",
            sortable: true,
            width: "80",
          },
          {
            text: "من",
            align: "center",
            value: "from",
            width: "80",
          },
          {
            text: "إلي",
            align: "center",
            value: "to",
            width: "80",
          },
          {
            text: "السعر",
            align: "center",
            value: "price",
            width: "80",
          },
          {
            text: "عدد الطلبات",
            align: "center",
            value: "num_of_orders",
            width: "80",
          },
          {
            text: "العملة",
            align: "center",
            value: "currency",
            width: "80",
          },
          {
            text: "متاج",
            align: "center",
            value: "is_free",
            width: "80",
          },
          {
            text: "مفعل",
            align: "center",
            value: "is_active",
            width: "80",
          },
          {
            text: "التحكم",
            align: "center",
            value: "actions",
            width: "80",
          },
        ];
      } else {
        return [
          {
            text: "#",
            align: "center",
            value: "index",
            sortable: true,
            width: "80",
          },
          {
            text: "From",
            align: "center",
            value: "from",
            width: "80",
          },
          {
            text: "To",
            align: "center",
            value: "to",
            width: "80",
          },
          {
            text: "Price",
            align: "center",
            value: "price",
            width: "80",
          },
          {
            text: "Number Of Orders",
            align: "center",
            value: "num_of_orders",
            width: "80",
          },
          {
            text: "Currency",
            align: "center",
            value: "currency",
            width: "80",
          },
          {
            text: "Free",
            align: "center",
            value: "is_free",
            width: "80",
          },
          {
            text: "Active",
            align: "center",
            value: "is_active",
            width: "80",
          },
          {
            text: "Actions",
            align: "center",
            value: "actions",
            width: "80",
          },
        ];
      }
    },

    days() {
      return [
        {
          name: "السبت",
          id: "saturday",
        },

        {
          name: "الاحد",
          id: "sunday",
        },

        {
          name: "الاثنين",
          id: "monday",
        },

        {
          name: "الثلاثاء",
          id: "tuesday",
        },
        {
          name: "الاربعاء",
          id: "wednesday",
        },
        {
          name: "الخميس",
          id: "thursday",
        },
        {
          name: "الجمعة",
          id: "friday",
        },
      ];
    },
    // types() {
    //   return [
    //     {
    //       name: "Take Away",
    //       id: "take_away",
    //     },

    //     {
    //       name: "حجز مائدة",
    //       id: "reserve_table",
    //     },

    //     {
    //       name: "كلاهما معا",
    //       id: "both",
    //     },
    //   ];
    // },
    // types() {
    //   return [
    //     {
    //       name: 'العمل',
    //       id: 'working',
    //     },

    //     {
    //       name: 'التوصيل',
    //       id: 'delivery',
    //     },

    //     {
    //       name: 'كلاهما معا',
    //       id: 'both',
    //     },
    //   ]
    // },
  },

  methods: {
    // ===== Search Method =====
    filterClick(word) {
      if (!this.loading) {
        this.search = word;
        this.helper_filterSearch();
      }
    },

    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/work-time/add" });
    },
    showItem(item) {
      this.$router.push({ path: "/work-time/show/" + item.id });
    },
    editItem(item) {
      if (!item.times.length) {
        this.itemtoEdit = item;
        this.data.branches = item.branches;
        this.appendDaysRow();
      } else {
        this.itemtoEdit = item;
        this.data.branches = item.branches.map((ele) => {
          return {
            id: ele.id,
            name: ele.full_name,
          };
        });
      }
      this.dialogEditDay = !this.dialogEditDay;
      // this.setRows();
    },
    validateForm() {
      this.btnIsLoading = true;

      const frmData = new FormData();
      let keep = true;

      for (let el of this.itemtoEdit.times) {
        if (!el.from || !el.to) {
          console.log(el.from + "space" + el.to);
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.times"),
            position: "bottomRight",
          });
          keep = false;
          break;
        }
        if (!el.price) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.prices"),
            position: "bottomRight",
          });
          keep = false;
          break;
        }
        if (!el.num_of_orders) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.num_of_orders"),
            position: "bottomRight",
          });
          keep = false;
          break;
        }
      }
      if (!keep) {
        this.btnIsLoading = false;
        return;
      }
      frmData.append("day", this.itemtoEdit.day);
      // frmData.append("branch_id", this.$route.query.branch_id);
      this.data.branches.forEach((el, index) => {
        if (el.id) {
          frmData.append(`branches[${index}][id]`, el.id);
        }
      });
      this.itemtoEdit.times.forEach((el, index) => {
        if (!el.new) {
          frmData.append(`workTimes[${index}][id]`, el.id);
        }
        frmData.append(`workTimes[${index}][from]`, el.from);
        frmData.append(`workTimes[${index}][to]`, el.to);
        frmData.append(`workTimes[${index}][price]`, el.price);
        frmData.append(`workTimes[${index}][is_active]`, el.is_active ? 1 : 0);
        frmData.append(`workTimes[${index}][is_free]`, el.is_free ? 1 : 0);
        frmData.append(`workTimes[${index}][num_of_orders]`, el.num_of_orders);
      });
      let url = null;
      if (this.itemtoEdit.id) {
        frmData.append("_method", "PUT");
        url = `work_day/${this.itemtoEdit.id}`;
      } else {
        url = `work_day`;
      }

      this.$axios
        .post(url, frmData)
        .then(() => {
          this.btnIsLoading = false;

          this.dialogEditDay = false;
          this.itemtoEdit = null;
          this.setRows();
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("editSuccess"),
            position: "bottomRight",
          });
          // this.$nextTick(() => )

          // window.location.reload();
        })
        .catch((err) => {
          this.btnIsLoading = false;
          console.log(err);
        });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      let url = "";
      console.log(this.itemtoDelete);
      if (this.itemtoDelete) {
        if (this.itemtoDelete.name) {
          url = `work_day/${this.itemtoDelete.id}`;
        } else {
          url = `work_day/time/${this.itemtoDelete.id}`;
        }
      } else {
        url = "work_day/delete-all";
      }
      this.$axios({
        method: "DELETE",
        url: url,
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return item.id != this.itemtoDelete.id;
          });
          this.dialogDelete = false;
          this.setRows();
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },

    // ==================== End CRUD ====================

    getBranches() {
      this.$axios({
        method: "GET",
        url: "get_all_branch",
      })
        .then((res) => {
          const result = res.data.data.map((el) => {
            return {
              id: el.id,
              name: el.full_name,
            };
          });
          this.branches = result;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Set Rows
    setRows() {
      this.$axios({
        method: "GET",
        url: "work_day",
      })
        .then((res) => {
          const result = res.data.data;

          result.map((el) => {
            this.work_days.map((work) => {
              if (work.day == el.day) {
                work["id"] = el.id;
                // const type = this.types.filter((type) => type.id == el.type);
                work.times = el.times;
                work.branches = el.branches;
              }

              // if (el.branches.length > 0) {
              //   this.data.branches = el.branches.map((ele) => {
              //     return {
              //       id: ele.id,
              //       name: ele.full_name,
              //     };
              //   });
              // } else {
              //   this.data.branches = null;
              // }
              if (el.id == this.itemtoEdit?.id) {
                this.data.branches = el.branches.map((ele) => {
                  return {
                    id: ele.id,
                    name: ele.full_name,
                  };
                });
              }
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    appendDaysRow() {
      this.itemtoEdit.times.push({
        from: null,
        to: null,
        is_active: null,
        price: null,
        is_free: null,
        id: this.createRandomString(),
        new: true,
        branches: [],
      });
    },
    saveTimeFrom(time, index) {
      this.$refs[`time_from_${index}`][0].save(time);
    },
    saveTimeTo(time, index) {
      this.$refs[`time_to_${index}`][0].save(time);
    },
    async deleteDaysRow(time) {
      if (!time.new) {
        await this.$axios.delete(`work_day/time/${time.id}`);
      }
      this.itemtoEdit.times = this.itemtoEdit.times.filter(
        (el) => el.id != time.id
      );
      if (!this.itemtoEdit.times.length) {
        this.appendDaysRow();
      }
    },
  },
  created() {
    this.setRows();
    this.getBranches();
  },

  // ======= hooks
};
</script>

<style lang="scss">
.v-dialog {
  .edit-work-day.v-card {
    padding-top: 0;
    padding-bottom: 20px !important;
    .v-card__title {
      background: var(--mainColor-2) !important;
      color: #fff;
      margin-bottom: 0;
      justify-content: center;
    }
    .input_wrapper.top_label {
      border-radius: 10px !important;
      height: auto !important;
      * {
        font-size: 16px !important;
      }
      .multiselect .multiselect__tags {
        padding: 0 !important;
      }
    }
  }
}
.v-card.times-card {
  border: 1px solid var(--mainColor-2) !important;
  .v-card__title {
    background: var(--mainColor-2) !important;
    color: #fff;
    justify-content: center;
  }
  .v-card__text {
    min-height: 100px;
  }
  .times {
    > div {
      padding-top: 4px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      &:first-child {
        border-bottom: 1px solid;
        border-color: var(--mainColor-2);
      }
    }
  }
}

// .v-dialog__content .v-dialog {
//   max-width: 100% !important;
//   width: 100% !important;
// }
.work_time_append_btn {
  color: var(--mainColor-2);
  transition: all 0.3s;
  &:hover {
    scale: 1.2;
    opacity: 0.7;
  }
}
</style>
