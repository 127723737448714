<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateForm">
        <div class="container">
          <div class="row">
            <section class="p-3">
              <div
                class="row productDetails mb-2"
                v-for="(row, index) in data.work_time"
                :key="index"
              >
                <div class="col-11 mx-auto">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="select-wrapper">
                        <div class="input_wrapper top_label">
                          <label for="" class="form-label">النوع</label>
                          <multiselect
                            v-model="row.type"
                            :options="types"
                            label="name"
                            track-by="id"
                            :show-labels="false"
                            placeholder=""
                            :searchable="true"
                          ></multiselect>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="select-wrapper">
                        <div class="input_wrapper top_label">
                          <label for="" class="form-label"> من </label>

                          <v-menu
                            :ref="`time_from_${index}`"
                            v-model="row.time_from_modal"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="row.from"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="row.from"
                                label=" "
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="row.time_from_modal"
                              v-model="row.from"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="error"
                                @click="row.time_from_modal = false"
                              >
                                الغاء
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="saveTimeFrom(row.from, index)"
                              >
                                حفظ
                              </v-btn>
                            </v-time-picker>
                          </v-menu>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="select-wrapper">
                        <div class="input_wrapper top_label">
                          <label for="" class="form-label"> الي </label>

                          <v-menu
                            :ref="`time_to_${index}`"
                            v-model="row.time_to_modal"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="row.to"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="row.to"
                                label=" "
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="row.time_to_modal"
                              v-model="row.to"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="error"
                                @click="row.time_to_modal = false"
                              >
                                الغاء
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="saveTimeTo(row.to, index)"
                              >
                                حفظ
                              </v-btn>
                            </v-time-picker>
                          </v-menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-1 py-0 d-flex justify-center align-center">
                  <span
                    v-if="index == data.work_time.length - 1"
                    class="append mx-1"
                    @click="appendDaysRow"
                  >
                    <i class="fas fa-plus-circle"></i>
                  </span>

                  <span
                    v-if="data.work_time.length > 1"
                    class="append mx-1"
                    @click="deleteDaysRow(index)"
                  >
                    <i class="fas fa-minus-circle"></i>
                  </span>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div class="buttons_wrapper">
          <button class="button_style_1" :disabled="btnIsLoading">
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
import GlobalStore from "@/helpers/store.js";
import GlobalService from "@/helpers/services.js";
export default {
  name: "add-wallet",

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.work-time.title"),
          disabled: false,
          href: "/work-time/show-all",
        },
        {
          text: this.$t("breadcrumb.work-time.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,
      // ========== create_data
      data: {
        work_time: [
          {
            time_from_modal: false,
            time_to_modal: false,
            day: null,
            from: null,
            to: null,
            type: null,
            exeptions: ["time_from_modal", "time_to_modal"],
          },
        ],
      },
    };
  },

  computed: {
    days() {
      return [
        {
          name: "السبت",
          id: "saturday",
        },

        {
          name: "الاحد",
          id: "sunday",
        },

        {
          name: "الاثنين",
          id: "monday",
        },

        {
          name: "الثلاثاء",
          id: "tuesday",
        },
        {
          name: "الاربعاء",
          id: "wednesday",
        },
        {
          name: "الخميس",
          id: "thursday",
        },
        {
          name: "الجمعة",
          id: "friday",
        },
      ];
    },
    types() {
      return [
        {
          name: "العمل",
          id: "working",
        },

        {
          name: "التوصيل",
          id: "delivery",
        },

        {
          name: "كلاهما معا",
          id: "both",
        },
      ];
    },
  },
  methods: {
    validateForm() {
      this.btnIsLoading = true;

      for (let row of this.data.work_time) {
        if (!row.day) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.day"),
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!row.from) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.from"),
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!row.to) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.to"),
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!row.type) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.type"),
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        }
      }
      this.submitData();
    },

    submitData() {
      this.btnIsLoading = true;
      this.globalStore
        .submitData({ data: this.data }, "work_time")
        .then((res) => {
          this.btnIsLoading = false;
          if (res.data.status == "fail") {
            return;
          }
          this.$router.push("/work-time/show-all");
        });
    },
    appendDaysRow() {
      this.data.work_time.push({
        day: null,
        from: null,
        to: null,
        type: null,
        exeptions: ["time_from_modal", "time_to_modal"],
      });
    },
    saveTimeFrom(time, index) {
      this.$refs[`time_from_${index}`][0].save(time);
    },
    saveTimeTo(time, index) {
      this.$refs[`time_to_${index}`][0].save(time);
    },
    deleteDaysRow(index) {
      this.data.work_time.splice(index, 1);
    },
  },
  globalStore: null,
  globalService: null,
  created() {
    this.globalStore = new GlobalStore();
    this.globalService = new GlobalService();
  },
};
</script>
